<!-- 服务与支持 -->
<template>
  <div class="page">
    <div class="imgbox">
      <img src="../assets/about/banner01.png" alt="" />
    </div>
    <div class="contetnt">
      <div class="Service_commitment">
        <div class="title">
          <span></span>
          <span>服务承诺</span>
        </div>
        <div class="infor">
          <div class="left_img">
            <img src="../assets/ServiceSupport/Service_commitment.png" alt="" />
          </div>
          <div class="right_text">
            <img
              src="../assets/ServiceSupport/commitment_text_img.jpg"
              alt=""
            />
            <div class="item">
              <span>耐心</span>
              <p>在服务过程中始终保持耐心，百问不烦，百答不厌。</p>
            </div>
            <div class="item">
              <span>细心</span>
              <p>为客户服务细致周到，不漏掉一个问题，不放过一个细节。</p>
            </div>
            <div class="item">
              <span>高效</span>
              <p>在服务过程中，以最快的速度帮助客户解决问题。</p>
            </div>
          </div>
        </div>
      </div>
      <div class="Service_content">
        <div class="title">
          <span></span>
          <span>服务内容</span>
        </div>
        <div class="list">
          <div class="item">
            <img
              src="../assets/ServiceSupport/consulting_service1.jpg"
              alt=""
            />
            <span>咨询规划服务</span>
            <p>
              旭瑞智能提供不限于智慧水利、智慧城管、智慧农业等服务的咨询定制，以客户的成功为导向，深层分析使用场景，实地调研客户需求，构筑贴合实际业务，集科学化、精细化、智能化为一体的智慧物联网解决方案。
            </p>
          </div>
          <div class="item">
            <img
              src="../assets/ServiceSupport/consulting_service2.jpg"
              width="60px"
              height="60px"
              alt=""
            />
            <span>定制设计服务</span>
            <p>
              旭瑞智能拥有多年的行业服务经验和项目管理经验，每一次设计都由专家团队亲自把关执行，经过多次审议调整之后，才会确定更符合客户需求的解决方案。
            </p>
          </div>
          <div class="item">
            <img
              src="../assets/ServiceSupport/consulting_service3.jpg"
              width="60px"
              height="60px"
              alt=""
            />
            <span>培训实施服务</span>
            <p>
              客户签约之后，旭瑞智能会立刻安排功能培训相关事宜，由实施的专业工程师督办执行，严格按照项目流程控制实施并提供定期回访维护工作，确保解决客户所有疑虑。
            </p>
          </div>
          <div class="item">
            <img
              src="../assets/ServiceSupport/consulting_service4.jpg"
              width="60px"
              height="60px"
              alt=""
            />
            <span>安全运维服务</span>
            <p>
              系统在运行期间,建立防护、检测和恢复的闭环安全机制,保证业务系统安全、持续的运转。旭瑞智能依据符合IOS9001:2000标准的支持体系,提供包括基础服务(远程)，现场服务等安全运维服务。根据具体情况,又可形成多种组合，既专业又灵活。
            </p>
          </div>
        </div>
      </div>
      <div class="response_time">
        <div class="title">
          <span></span>
          <span>服务响应时间</span>
        </div>
        <div class="table">
          <table>
            <thead>
              <th>问题登记</th>
              <th>问题描述</th>
              <th>响应时间</th>
              <th>解决时间</th>
            </thead>
            <tbody>
              <tr>
                <td>1级</td>
                <td>
                  影响系统功能正常使用的问题，如客户数据无法加载，数据无法提交等。
                </td>
                <td>10分钟</td>
                <td>1小时</td>
              </tr>
              <tr>
                <td>2级</td>
                <td>
                  系统原因导致软件功能出现异常，但不影响正常的数据操作和提交。
                </td>
                <td>20分钟</td>
                <td>2小时</td>
              </tr>
              <tr>
                <td>3级</td>
                <td>一半使用或操作性问题，如对系统操作不熟悉等常见问题。</td>
                <td>及时响应</td>
                <td>
                  即时安排（远程协助服务）<br />a)拨打7x24小时客户服务热线<br />b)直接联系对接技术人员微信
                </td>
              </tr>
              <tr>
                <td>4级</td>
                <td>在系统使用过程中，对系统的建议或需求反馈。</td>
                <td>售后人员即时反馈研发部</td>
                <td>3天内给予答复（电话、微信、邮件）</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="Service_strength">
        <div class="title">
          <span></span>
          <span>服务优势</span>
        </div>
        <div class="list">
          <ul>
            <li>
              <img src="../assets/ServiceSupport/strength1.png" alt="" />
              <div>客户参与，迎接变化</div>
              <p>
                对于一套有价值的解决方案，客户的参与比签
                订一份完善的合同更高效。客户作为研发团队
                成员参与开发过程，把市场和业务的变化随时
                反馈给团队，实现从用户体验到研发的完整循
                环，保证最终的软件能真正促进业务的发展。
              </p>
            </li>
            <li>
              <img src="../assets/ServiceSupport/strength2.png" alt="" />
              <div>灵活方便，快速启动</div>
              <p>
                客户无需花大量的时间准备需求文档，也无需
                困顿于项目的评估或报价。在充分沟通、了 解
                客户的诉求之后，我们将从痛点出发，分析基
                于业务的真正需求并确定优先级，每两周提供
                可供客户使用和测试的产品，不断迭代。
              </p>
            </li>
            <li>
              <img src="../assets/ServiceSupport/strength3.png" alt="" />
              <div>稳定团队，长期服务</div>
              <p>
                我们致力于建立一个适合软硬件工程师工作和
                发展的企业环境，通过远程办公等工作模式提
                升程序员满意度，以保持稳定的团队为客户提
                供长期的维护和更新服务，因为团队成员对客
                户业务的持续了解是高效服务的有利保证。
              </p>
            </li>
            <li>
              <img src="../assets/ServiceSupport/strength4.png" alt="" />
              <div>完善可行的运维管理制度</div>
              <p>
                为保障服务质量，我们通过对日常运维工作所
                涉及的内容进行分类整理，包含人员、过程、
                技术和资源，在经过加工提炼后形成详细的运
                维服务标准和规范、管理标准和技术标准，标
                准的执行将贯穿在整个运维服务生命周期中， 不断完善运维服务标准。
              </p>
            </li>
            <li>
              <img src="../assets/ServiceSupport/strength5.png" alt="" />
              <div>高效的运维服务保障体系</div>
              <p>
                为实现本地化的实施运维保障服务，我们将在
                项目发生地设立分公司及运维中心，同时打造
                专业的服务技术团队，并配备多辆专业服务车
                辆24小时待命，第一时间赶赴故障现场解决问!
                题，加快加速响应支援支持。
              </p>
            </li>
            <li>
              <img src="../assets/ServiceSupport/strength6.png" alt="" />
              <div>退款保障</div>
              <p>
                客户满意度是衡量服务质量的最终标准。如果
                旭瑞智能提交的工作成果无法令客户满意，旭
                瑞智能将根据客户要求和退款标准进行退款。
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less">
.page {
  .contetnt {
    .Service_commitment {
      padding: 50px 0;
      width: 60%;
      margin: 50px auto;
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        span:nth-child(1) {
          display: block;
          width: 6px;
          height: 30px;
          background-color: #004aa1;
          margin-right: 10px;
        }
        span {
          font-size: 34px;
          font-weight: 600;
        }
      }
      .infor {
        display: flex;
        .left_img {
          width: 50%;
          img {
            width: 100%;
          }
        }
        .right_text {
          width: 44%;
          padding: 3%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          background-color: rgb(238, 238, 238);
          img {
            width: 50px;
          }
          .item {
            span {
              font-size: 18px;
              font-weight: 600;
            }
            p {
              font-size: 17px;
            }
          }
        }
      }
    }
    .Service_content {
      padding: 100px 20%;
      background-color: rgb(22, 88, 166);
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        color: #fff;
        span:nth-child(1) {
          display: block;
          width: 6px;
          height: 32px;
          background-color: #fff;
          margin-right: 10px;
        }
        span {
          font-size: 36px;
          font-weight: 600;
        }
      }
      .list {
        display: flex;
        justify-content: space-between;
        .item {
          width: 20%;
          padding: 2%;
          height: 300px;
          background-color: #fff;
          border-radius: 8px;
          text-align: center;
          img {
            width: 60px;
          }
          span {
            display: block;
            font-size: 18px;
            font-weight: 600;
            margin-top: 10px;
            margin-bottom: 15px;
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
    .response_time {
      padding: 100px 20%;
      .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span:nth-child(1) {
          display: block;
          width: 60px;
          height: 6px;
          background-color: #004aa1;
        }
        span:nth-child(2) {
          font-size: 34px;
          font-weight: 600;
        }
      }
      .table {
        margin-top: 40px;
        table {
          margin: auto;
          border-radius: 10px;
          box-shadow: 0 0 10px 5px #dcdcdc;
          thead {
            height: 80px;
            background-color: #1658a6;
            th {
              color: white;
              min-width: 120px;
            }
            th:nth-child(1) {
              border-top-left-radius: 10px;
              width: 150px;
            }
            th:nth-child(2) {
              width: 497px;
            }
            th:nth-child(3) {
              width: 170px;
            }
            th:nth-child(4) {
              border-top-right-radius: 10px;
              width: 335px;
            }
          }
          tbody {
            background-color: #d3d3d3;
            tr {
              height: 100px;
              td:nth-child(1) {
                color: #1658a6;
                font-weight: 700;
                text-align: center;
              }
              td:nth-child(2) {
                padding: 0 25px;
              }
              td:nth-child(3) {
                padding: 0 25px;
              }
              td:nth-child(4) {
                padding: 0 25px;
              }
            }
            tr:nth-child(4) {
              td:nth-child(1) {
                border-bottom-left-radius: 10px;
              }
              td:nth-child(4) {
                border-bottom-right-radius: 10px;
              }
            }
          }
        }
      }
    }
    .Service_strength {
      padding: 50px 0;
      width: 60%;
      margin: 50px auto;
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        span:nth-child(1) {
          display: block;
          width: 6px;
          height: 30px;
          background-color: #004aa1;
          margin-right: 10px;
        }
        span {
          font-size: 34px;
          font-weight: 600;
        }
      }
      .list {
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            border-right: 1px solid;
            border-bottom: 1px solid;
            padding: 0 20px;
            width: 29.5%;
            img {
              margin-top: 30px;
            }
            div {
              font-size: 20px;
              font-weight: 700;
              margin: 20px 0 20px 0;
            }
            p {
              margin-bottom: 30px;
              font-size: 17px;
            }
          }
          li:nth-child(3) {
            border-right: 0px;
          }
          li:nth-child(4),
          li:nth-child(5),
          li:nth-child(6) {
            border-bottom: 0px;
          }
          li:nth-child(6) {
            border-right: 0px;
          }
        }
      }
    }
  }
}
</style>